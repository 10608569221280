import Papa from "papaparse";
import { useToast } from "@chakra-ui/react";
import {
  useContentStrategyV2_UpdateOrderContentStrategyMutation,
  useContentStrategyV2TaskWorkspaceQuery,
} from "../../../../generated/graphql";
import { TaskWorkspaceCommonProps } from "../../task-workspace-common-props";

export function useContentStrategyV2(options: TaskWorkspaceCommonProps) {
  const { task: propsTask, onComplete } = options;

  const queryResult = useContentStrategyV2TaskWorkspaceQuery({
    fetchPolicy: "network-only",
    variables: {
      taskId: propsTask.id,
      ignoreUserTaskPermission: true,
    },
  });
  const toast = useToast();
  const [updateOrderContentStrategy] = useContentStrategyV2_UpdateOrderContentStrategyMutation();

  const task = queryResult.data?.task ?? null;
  const customer = task?.customer ?? null;
  const customerQuestionnaire = customer?.customerQuestionnaire ?? null;
  const customerProposal = task?.customerProposal ?? null;
  const orderContentStrategies = customerProposal?.orderContentStrategies ?? [];
  const reviewTasks = (task?.reviewTasks.nodes ?? []).filter((reviewTask) => !!reviewTask.reviewComment);

  const canSubmit = orderContentStrategies.every((x) => x.isComplete);

  function onFileSelected(file: any) {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (results: any) => {
        let successCount = 0;
        let errorCount = 0;
        for (const result of results.data) {
          const cloneOrderContentStrategy = JSON.parse(JSON.stringify(orderContentStrategies));
          const orderContentStrategy = cloneOrderContentStrategy.find(
            (x: any) => x.order?.id === parseInt(result["orderId"], 10)
          );
          if (orderContentStrategy) {
            orderContentStrategy.topic = result["topic"] ? result["topic"] : orderContentStrategy.topic;
            orderContentStrategy.customerKeywordId = result["customerKeywordId"]
              ? parseInt(result["customerKeywordId"], 10)
              : orderContentStrategy.customerKeywordId;
            orderContentStrategy.orderHaloKeywords = result["orderHaloKeywords"]
              ? result["orderHaloKeywords"].split(" ").map((x: string) => {
                  const customerKeyword = {
                    id: parseInt(x, 10),
                  };
                  return { customerKeyword };
                })
              : orderContentStrategy.orderHaloKeywords;
            orderContentStrategy.purposeOfContent = result["purposeOfContent"]
              ? result["purposeOfContent"]
              : orderContentStrategy.purposeOfContent;
            orderContentStrategy.linkTo = result["linkTo"] ? result["linkTo"] : orderContentStrategy.linkTo;
            orderContentStrategy.voice = result["voice"] ? result["voice"] : orderContentStrategy.voice;
            orderContentStrategy.sampleContent = result["sampleContent"]
              ? result["sampleContent"]
              : orderContentStrategy.sampleContent;
            orderContentStrategy.focusPoints = result["focusPoints"]
              ? result["focusPoints"]
              : orderContentStrategy.focusPoints;
            orderContentStrategy.webPage = result["webPage"] ? result["webPage"] : orderContentStrategy.webPage; //For web copy
            orderContentStrategy.pageType = result["pageType"] ?? orderContentStrategy.pageType; //For web copy

            const response = await updateOrderContentStrategy({
              variables: {
                input: {
                  orderContentStrategyId: orderContentStrategy.id,
                  topic: orderContentStrategy.topic,
                  customerKeywordId: orderContentStrategy.customerKeywordId,
                  orderHaloKeywords: orderContentStrategy.orderHaloKeywords.map((x: any) => x.customerKeyword.id),
                  purposeOfContent: orderContentStrategy.purposeOfContent,
                  linkTo: orderContentStrategy.linkTo,
                  voice: orderContentStrategy.voice,
                  sampleContent: orderContentStrategy.sampleContent,
                  focusPoints: orderContentStrategy.focusPoints,
                  webPage: orderContentStrategy.webPage,
                  pageType: orderContentStrategy.pageType,
                },
              },
            });
            if (response.data?.updateOrderContentStrategy.ok) {
              successCount++;
            } else {
              errorCount++;
            }
          }
        }
        toast({
          title: "File uploaded",
          description: `${successCount} content strategies updated successfully. ${errorCount} content strategies failed to update.`,
          status: "success",
        });
        await queryResult.refetch();
      },
    });
  }

  function onDownloadKeywords() {
    window.open(`${process.env.REACT_APP_API_BASE}/files/customers/${customer?.id ?? 0}/keywords/cs`, "_blank");
  }

  function onDownloadContentStrategy() {
    window.open(
      `${process.env.REACT_APP_API_BASE}/files/customers/${customer?.id ?? 0}/tasks/${task?.id ?? 0}/cs`,
      "_blank"
    );
  }

  function handleComplete() {
    if (!canSubmit) {
      toast({
        title: "Unable to Submit for Client Review",
        description: "All content strategies must be complete in order to submit for client review.",
        status: "error",
      });
      return;
    }
    onComplete();
  }

  return {
    isLoading: queryResult.loading,
    task,
    customer,
    customerQuestionnaire,
    customerProposal,
    orderContentStrategies,
    reviewTasks,
    canSubmit,
    handleComplete,
    onFileSelected,
    refetch: queryResult.refetch,
    onDownloadKeywords,
    onDownloadContentStrategy,
  };
}
